<template>
  <div class="game_bg">
    <div class="game_title padding-sm margin-top-xl">
      <img class="game_back" src="img/backw.png" alt="" @click="closePage">
      <span>认证</span>
    </div>
    <div class="g_cert">
      <img src="img/game/game_img.png" alt="">
    </div>
    <div class="g_cert_">
      <img src="img/game/game_img_2.png" alt="">
    </div>
    <div class="g_cert_3">
      <img src="img/game/game_img_3.png" alt="">
      <div class="btn_box">
        <div class="btn_item">
          <button :disabled="indexData.phone_status == 1"
            :class="indexData.phone_status == 1 ? 'cert_disabled' : 'cert_button'" @click="goAppSub('certPhone')">{{
              phone_text }}</button>
        </div>
        <!-- <div class="btn_item">
          <button :disabled="indexData.new_user_status == 1"
            :class="indexData.new_user_status == 1 ? 'cert_disabled' : 'cert_button'" @click="goAppSub('certNewUser')">{{
              new_user_text }}</button>
        </div> -->
        <div class="btn_item">
          <button :disabled="indexData.is_auth_id_card == 1"
            :class="indexData.is_auth_id_card == 1 ? 'cert_disabled' : 'cert_button'"
            @click="goAppSub('realNameCertification')">{{ auth_master_text }}</button>
        </div>



      </div>
    </div>
    <!-- <div class="cert_box">
      <div class="margin-tb">
        <div class="display_flex justify-content_flex-justify align-items_center margin-bottom">
          <div class="cert_action display_flex align-items_center">
            <img src="img/game/cert_action1.png" alt="">
            <p>绑定手机号</p>
          </div>
          <button :disabled="indexData.phone_status == 1"
            :class="indexData.phone_status == 1 ? 'cert_disabled' : 'cert_button'" @click="goAppSub('certPhone')">{{
              phone_text }}</button>
        </div>
        <div class="display_flex justify-content_flex-justify align-items_center margin-bottom">
          <div class="cert_action display_flex align-items_center">
            <img src="img/game/cert_action2.png" alt="">
            <p>完成新人专享任务</p>
          </div>
          <button :disabled="indexData.new_user_status == 1"
            :class="indexData.new_user_status == 1 ? 'cert_disabled' : 'cert_button'" @click="goAppSub('certNewUser')">{{
              new_user_text }}</button>
        </div>
        <div class="display_flex justify-content_flex-justify align-items_center margin-bottom">
          <div class="cert_action display_flex align-items_center">
            <img src="img/game/cert_action3.png" alt="">
            <p>微信号上传并审核通过</p>
          </div>
          <button :disabled="indexData.auth_wechat_status == 1 || indexData.auth_wechat_status == 2"
            :class="(indexData.auth_wechat_status == 1 || indexData.auth_wechat_status == 2) ? 'cert_disabled' : 'cert_button'"
            @click="goAppSub('certWechat')">{{ auth_wechat_text }}</button>
        </div>
      </div>
    </div> -->
    <button class="cert_submit" :disabled="disabled" :class="disabled ? 'cert_disSub' : 'cert_goSub'"
      @click="goCert">申请认证</button>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/game.css"
import { applyForGod } from "@/api/api";
export default {
  name: 'certifiedGame',
  data() {
    return {
      indexData: {}
    };
  },
  computed: {
    disabled() {
      let phone_status = this.indexData.phone_status
      // let new_user_status = this.indexData.new_user_status
      // let auth_wechat_status = this.indexData.auth_wechat_status
      if (phone_status == 1) {
        return false
      } else {
        return true
      }
    },
    phone_text() {
      if (this.indexData.phone_status == 0) {
        return "去绑定"
      } else {
        return "已绑定"
      }
    },
    // new_user_text() {
    //   if (this.indexData.new_user_status == 0) {
    //     return "去完成"
    //   } else {
    //     return "已完成"
    //   }
    // },
    auth_master_text() {
      if (this.indexData.is_auth_master == 0) {
        return "去实名"
      } else {
        return "已实名"
      }
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.fetchData()
  },
  methods: {
    fetchData() {
      applyForGod().then(response => {
        this.indexData = response.data
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    goCert() {
      this.$router.push({ path: '/gameList' })
    },
    closePage() {
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
      })
    },
    goAppSub(key) {
      let params = '调用'
      console.log(key)
      this.$bridge.callhandler(key, params, (data) => {
        // 处理返回数据
      })
    }

  },
  watch: {
    '$route': "fetchData"
  }
};
</script>
